$fonts-url: '../assets/fonts/';

@mixin font-face($file-name, $font-family, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-display: swap;
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url('#{$fonts-url}#{$file-name}.woff2') format('woff2'),
      url('#{$fonts-url}#{$file-name}.woff') format('woff');
  }
}

@include font-face('tsvetnoy-book', 'Tsvetnoy-Book');
@include font-face('tsvetnoy-display', 'Tsvetnoy-Display');
@include font-face('tsvetnoy-medium', 'Tsvetnoy-Medium', 500);
