@use '@app/styles/vars.scss';

@mixin small-text {
  font-size: clamp(0.875rem, 0.833vw, 1rem);
}

@mixin medium-icon {
  --arrow-icon-size: 40px;
}

@mixin small-icon {
  --arrow-icon-size: 28px;
}

.arrow-link {
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: fit-content;

  &--medium-text {
    font-size: clamp(1rem, 1.25vw, 1.5rem);
  }

  &--small-text {
    @include small-text;
  }

  &--medium-icon {
    @include medium-icon;
  }

  &--small-icon {
    @include small-icon;
  }

  &__icon {
    rotate: -90deg;

    width: var(--arrow-icon-size);
    min-width: var(--arrow-icon-size);
    height: var(--arrow-icon-size);

    transition-duration: 0.3s;
  }

  &:hover > &__icon {
    transform: translateY(20%) scale(1.2);
  }

  @media #{vars.$mobile} {
    @include small-text;
    @include small-icon;
  }
}
