@use "~normalize.css/normalize";
@use "@app/styles/components/form";
@use "@app/styles/reset";
@use "@app/styles/fonts";
@use "@app/styles/colors";
@use "@app/styles/vars";
@use "~swiper/css";
@use "~swiper/css/pagination";
@use "~swiper/css/effect-cards";

* {
  color: unset;
}

:root {
  --font-title: "Tsvetnoy-Display";
  --font-default: "Tsvetnoy-Medium";
  --font-thin: "Tsvetnoy-Book";
}

body {
  overflow-x: hidden;
  overflow-y: overlay;

  font-family: Tsvetnoy-Medium, Notosans, sans-serif;
  font-weight: 400;
  color: colors.$main-text-color;

  background-color: colors.$main-bg-color;
}

button {
  cursor: pointer;
}

img {
  -webkit-user-drag: none;
}

.scroll-disabled {
  overflow: hidden;
}

.h1,
.h2,
.h3,
.h4 {
  font-family: Tsvetnoy-Display;
}
