@use '@app/styles/vars';
@use '@app/styles/mixins';
@use '@app/styles/colors';

.cookies {
  $popup-padding: clamp(0.75rem, 1.04vw, 1.25rem);

  position: fixed;
  z-index: 10;
  right: vars.$page-inline-padding;

  margin-bottom: vars.$page-inline-padding;
  padding: clamp(0.75rem, 1.04vw, 1.25rem);

  background-color: colors.$main-bg-color;
  border-radius: clamp(0.75rem, 1.04vw, 1.25rem);
  box-shadow: 0 1px 17px rgb(0 0 0 / 13%);

  @media #{vars.$not-mobile} {
    width: 383px;
  }

  @media #{vars.$mobile} {
    width: 90vw;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $popup-padding;
  }

  &__details-btn {
    padding-block: 0;
  }

  &__buttons {
    position: absolute;
    inset: auto $popup-padding $popup-padding auto;
    display: flex;
    justify-content: flex-end;
  }

  &__accept-btn {
    $border-radius: 1.875rem;

    @include mixins.dynamic-gradient-bg(
      linear-gradient(-45deg, #f7b63d, #f19833, #687cf2),
      8s,
      200% 200%
    );
    @include mixins.font(clamp(0.8rem, 0.84vw, 1rem), $weight: 500);

    padding: clamp(8px, 0.65vw, 12px) clamp(12px, 1vw, 20px);
    color: colors.$main-inverted-text-color;
    border-radius: $border-radius;

    &::before {
      @include mixins.dynamic-gradient-bg(
        linear-gradient(-45deg, #f7f43d 10%, #23a74a 40%, #4b94b6 95%),
        5s,
        200% 200%
      );

      content: '';

      position: absolute;
      z-index: -1;
      inset: 0;

      opacity: 0;
      border-radius: $border-radius;

      transition: opacity 0.5s linear;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  &__details-popover {
    display: flex;
    flex-direction: column;
    gap: $popup-padding;

    @media #{vars.$not-mobile} {
      width: max(37vw, 420px);
    }
  }

  &__details-title {
    @include mixins.font(clamp(1.5rem, 1.667vw, 2rem));
  }

  &__agreement,
  &__policy {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));
  }

  &__policy-link {
    text-decoration: underline !important;
  }

  &__close-btn {
    margin-top: $popup-padding;
  }
}
