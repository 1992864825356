@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';
@use '@app/styles/mixins.scss';

.search {

  &__control-icon {
    &--cross {
      scale: 1.66;
    }
  }

  &.text-input {
    @include mixins.font(clamp(0.875rem, 1.04vw, 1.25rem));

    overflow: hidden;
    display: none;

    width: 0;
    padding-inline: 0;

    border-color: colors.$deep-inverted-border-color;

    &::placeholder {
      color: colors.$tp-text-color;
    }
  }

  &__search-btn {
    position: absolute;
    right: calc(5vw + 0.6em);
    bottom: 0;
    visibility: hidden;
  }
}