@use '@app/styles/mixins';
@use '@app/styles/colors';

@mixin control {
  @include mixins.font(clamp(0.875rem, 1.04vw, 1.25rem));

  user-select: none;

  position: relative;

  display: flex;
  gap: 1em;
  align-items: center;

  width: fit-content;
  padding: 0.6em 0.8em;

  border: 1px solid colors.$faint-text-color;
  border-radius: 1.25em;
}
