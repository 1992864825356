@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';

.scroll-up {
  cursor: pointer;
  inset: auto auto clamp(22px, 2.1vw, 40px) clamp(22px, 2.1vw, 40px);
  padding: 0.5rem;
  position: fixed;
  z-index: 10;

  &__icon {
    transition-duration: 0.5s;

    &--flipped {
      rotate: 180deg;
    }
    @media #{vars.$mobile} {
      height: 28px;
      width: 28px;;
    }
  }
}
