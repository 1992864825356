$main-text-color: var(--main-text-color, #1f1f1f);
$main-inverted-text-color: var(--main-inverted-text-color, #fff);
$pale-text-color: var(--pale-text-color, #ccc);
$faint-text-color: var(--faint-text-color, #b7b7b7);
$dim-text-color: var(--dim-text-color, #d2d2d2); // #1f1f1f with 0.2 opacity
$tp-text-color: var(--tp-text-color, #1f1f1f66); // #1f1f1f with 0.4 opacity
$tp-inverted-text-color: var(--tp-inverted-text-color, #fff6); // #fff with 0.4 opacity
$error-color: var(--error-color, #e90070);

$main-underline-color: var(--main-underline-color, #1f1f1f7f);
$main-inverted-underline-color: var(--main-inverted-underline-color, #fff7);

$main-bg-color: var(--main-bg-color, #fff);
$main-inverted-bg-color: var(--main-inverted-bg-color, #1f1f1f);
$deep-inverted-bg-color: var(--deep-inverted-bg-color, #000);
$secondary-bg-color: var(--secondary-bg-color, #f6f6f6); // rgba(31,31,31,0.04) or #1F1F1F0A
$faint-bg-color: var(--faint-bg-color, #f5f5f5);
$pale-bg-color: var(--pale-bg-color, #efefef);
$secondary-inverted-bg-color: var(--secondary-inverted-bg-color, #000);

$main-border-color: var(--main-border-color, $main-text-color);
$main-inverted-border-color: var(--main-inverted-border-color, $main-inverted-text-color);
$secondary-inverted-border-color: var(--secondary-inverted-border-color, #808080);
$dim-inverted-border-color: var(--dim-inverted-border-color, #333);
$pale-border-color: var(--pale-border-color, #f1f1f1); // #1f1f1f with 0.063 opacity
$faint-border-color: var(--faint-border-color, #e6e6e6); // #000 with 0.1 opacity
$deep-inverted-border-color: var(--deep-inverted-border-color, #000);

$map-background-color: var(--map-background-color, #f8f5f6);


:export {
  faintBgColor: $faint-bg-color;
  mainBgColor: $main-bg-color;
  mainInvertedTextColor: $main-inverted-text-color;
  mainTextColor: $main-text-color;
  paleBgColor: $pale-bg-color;
}
