@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';
@use '@app/styles/mixins.scss';

.join-button {
  $border-radius: 1.875rem;

  @include mixins.dynamic-gradient-bg(linear-gradient(-45deg, #f7b63d, #f19833, #687cf2),
    8s,
    200% 200%);

  user-select: none;

  position: relative;

  padding: clamp(8px, 0.65vw, 12px) clamp(12px, 1vw, 20px);

  font-size: clamp(0.8rem, 1.25vw, 1.5rem);
  font-weight: 500;
  color: colors.$main-inverted-text-color;
  letter-spacing: -0.01em;

  border-radius: $border-radius;

  &::before {
    @include mixins.dynamic-gradient-bg(linear-gradient(-45deg, #f7f43d 10%, #23a74a 40%, #4b94b6 95%),
      5s,
      200% 200%);

    content: '';

    position: absolute;
    z-index: -1;
    inset: 0;

    opacity: 0;
    border-radius: $border-radius;

    transition: opacity 0.5s linear;
  }

  &:hover::before {
    opacity: 1;
  }
}