@use "@app/styles/colors.scss";

.drawer {
  &__overlay {
  }

  &__sidebar {
    box-shadow: rgb(0 0 0 / 20%) 0 8px 10px -5px, rgb(0 0 0 / 14%) 0 16px 24px 2px, rgb(0 0 0 / 12%) 0 6px 30px 5px;
    position: fixed;
    overflow-y: auto;

    &--left {
      inset: 0 auto 0 0;
      transform: translateX(-100%);
    }

    &--right {
      inset: 0 0 0 auto;
      transform: translateX(100%);
    }

    &--top {
      inset: 0 0 auto 0;
      transform: translateY(-100%);
    }

    &--bottom {
      inset: auto 0 0 0;
      transform: translateY(100%);
    }
  }
}
