@use '@app/styles/vars.scss' as vars;
@use '@app/styles/colors.scss';

@mixin full-height($multiplier: 1, $constraint: vars.$page-max-height, $prefix: '') {
  $prop: 'height';

  @if($prefix == 'min') {
    $prop: 'min-height';
  }

  @if ($prefix == 'max') {
    $prop: 'max-height';
  }

  #{$prop}: calc(min(100vh, $constraint) * $multiplier);
}

@mixin full-width {
  overflow: hidden;
  max-width: calc(100% + vars.$page-inline-padding * 2);
  margin-inline: calc(vars.$page-inline-padding * -1);
  padding-inline: vars.$page-inline-padding;
}

@mixin font($size, $family: Tsvetnoy-Medium, $weight: 400, $line-height: 1.2, $letter-spacing: -0.01em) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin main-bg {
  $gradient: linear-gradient(0deg,
  rgb(255 255 255 / 0%) 20%,
  colors.$main-bg-color 80%),
    linear-gradient(45deg,
  rgb(170 141 252 / 83%) 5%,
  rgb(253 174 60 / 80%) 15%,
  rgb(254 238 140 / 90%) 30%,
  rgb(255 255 0 / 67%) 40%,
  rgb(160 232 167) 60%,
  rgb(241 145 209 / 80%) 75%,
  rgb(66 236 238) 87%,
  #f7f7f7 95%);

  @media #{vars.$desktop} {
    @include dynamic-gradient-bg($gradient, $size: 300% 120%);

    background-attachment: fixed;
    backdrop-filter: unset;
  }

  @media #{vars.$portable} {
    position: relative;

    &::after {
      @include dynamic-gradient-bg($gradient, $size: 300% 120%);

      content: '';
      position: fixed;
      z-index: -1;
      inset: 0;
    }
  }
}

@mixin dynamic-gradient-bg(
  $gradient,
  $duration: 10s,
  $size: 300% 100%
) {
  background-image: $gradient;
  background-size: $size;
  backdrop-filter: blur(2px);
  animation: gradient $duration linear infinite;

  @keyframes gradient {
    0% {background-position: 0% 50%;}
    25% {background-position: 50% 0;}
    50% {background-position: 100% 50%;}
    75% {background-position: 50% 100%;}
    100% {background-position: 0% 50%;}
  }
}

@mixin box-shadow($second-layer-radius: 25px) {
  box-shadow: 0 1px 5px rgb(4 11 23 / 4%),
    0 5px $second-layer-radius rgb(0 0 0 / 6%),
    0 0 2px rgb(0 0 0 / 9%);
}

@mixin inline-slider {
  scrollbar-width: none;
  overflow-x: auto;
  flex-flow: row nowrap;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin section-title($font-size: clamp(1.75rem, 2.5vw, 3rem)) {
  margin-top: 0;
  font-family: Tsvetnoy-Display;
  font-size: $font-size;
  font-weight: 500;
}

@mixin image-common {
  user-select: none;
  object-fit: cover;
  object-position: center;

  -webkit-user-drag: none;
}

@mixin image-banner($max-height: 760px) {
  @include image-common;

  width: 100%;
  height: clamp(200px, calc(34vw + 10vh), $max-height);
}

@mixin card {
  @include card-caption;
  @include card-logo;

  &--hover {
    transition-duration: 0.5s;

    &:hover {
      transform: scale(1.03);
    }
  }

  &__bg {
    background-position: center;
    background-size: cover;
  }

  &__image {
    @include image-common();

    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-weight: 500;
    text-align: center;

    backdrop-filter: brightness(80%);
  }

  &__link {
    cursor: pointer;
    display: block;
  }
}

@mixin card-caption {
  &__caption {
    margin-top: clamp(0.75rem, 1.042vw, 1.25rem);
  }

  &__caption-title {
    font-size: clamp(1rem, 1.25vw, 1.5rem);
    font-weight: 500;
  }

  &__details {
    @include font(clamp(0.875rem, 1.042vw, 1.25rem), Tsvetnoy-Book);

    margin-top: 0.5rem;
  }
}

@mixin card-logo {
  &__picture {
    position: relative;
  }

  &__logo {
    position: absolute;
    inset: auto auto clamp(12px, 1.04vw, 20px) clamp(12px, 1.04vw, 20px);

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1 / 1;
    width: 52px;

    background-color: colors.$main-bg-color;
    border-radius: 12px;
  }

  &__logo-image {
    width: 52px;
  }
}

@mixin underline($color: colors.$main-underline-color) {
  position: relative;
  width: fit-content;

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -0.2em;
    left: 0;

    width: 10%;
    height: 0.0675em;

    opacity: 0;
    background-color: $color;

    transition-duration: 0.3s;
  }

  &:hover::after {
    width: 100%;
    opacity: 1;
  }
}

@mixin page-spacing($min-height: 100vh) {
  min-height: $min-height;
  padding: calc(clamp(1rem, 3.125vw, 3.75rem) + vars.$header-height) vars.$page-inline-padding clamp(1rem, 3.125vw, 3.75rem);
}

@mixin page-grid-gap {
  gap: clamp(2rem, 3.125vw, 3.75rem) min(1.56vw, 1.875rem);
}

@mixin page-title-group($title-font-size: '', $description-font-size: clamp(1.125rem, 1.67vw, 2rem)) {
  &__title-group {
    @include page-grid-gap;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;

    @media #{vars.$mobile} {
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    font-size: $description-font-size;
    font-weight: 500;
  }

  &__title {
    @if ($title-font-size != '') {
      @include section-title($title-font-size);
    }

    @else {
      @include section-title;
    }
  }
}
