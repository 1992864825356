@use '@app/styles/mixins';
@use '@app/styles/vars';
@use '@app/styles/colors';
@use '@app/components/components.mixins' as components;

.dropdown {
  position: relative;
  display: flex;

  &__inner {
    @include components.control;

    cursor: pointer;
    min-width: 115px;
  }

  &__label {
    position: absolute;
    top: -1.5rem;
    color: colors.$tp-text-color;
  }

  &__options {
    position: absolute;
    z-index: 1;
    inset: 100% auto auto 0;

    overflow: hidden;
    display: flex;
    flex-direction: column;

    height: 0;

    opacity: 0;
    background-color: colors.$main-bg-color;
    border: 0 solid colors.$faint-text-color;
    border-bottom-right-radius: 1.25em;
    border-bottom-left-radius: 1.25em;
  }

  &__option {
    display: flex;
    min-height: 2.2em;
    padding-inline: 0.8em;

    &:hover,
    &--selected {
      color: colors.$main-inverted-text-color;
      background-color: colors.$main-inverted-bg-color;
    }
  }

  &__option-btn {
    width: 100%;
    text-align: start;
  }

  &__icon {
    height: fit-content;
    transition-duration: 0.5s;

    @media #{vars.$not-mobile} {
      width: 24px;
      min-width: 24px;
    }

    @media #{vars.$mobile} {
      width: 20px;
      min-width: 20px;
    }

    &--flipped {
      rotate: 180deg;
    }
  }
}
