@use '@app/styles/mixins.scss';
@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';

.program-banner {

  $border-radius: clamp(16px, calc(19.83vw - 7.49vh), 300px);
  $gradient: radial-gradient(ellipse at 50% 80%, rgb(255 255 255 / 0%) 50%, rgb(252 255 255 / 100%) 82%),
    radial-gradient(circle at 50% 50%, rgb(255 186 0 / 81%) 24%, rgb(255 157 222 / 81%) 40%, rgb(0 220 228 / 86%) 75%, rgb(99 160 253 / 81%) 90%);

  content-visibility: auto;
  contain-intrinsic-size: 0 407px;
  padding: clamp(1.25rem, 3.125vw, 3.75rem) vars.$page-inline-padding clamp(2.5rem, 6.25vw, 7.5rem);
  background-color: colors.$main-bg-color;

  &__banner {
    @include mixins.dynamic-gradient-bg($gradient, 0s, 130% 100%);

    position: relative;

    display: flex;
    flex-direction: column;
    gap: clamp(2.2rem, 4.42vw, 5.3rem);
    align-items: center;
    justify-content: space-between;

    padding: clamp(60px, calc(3vw + 3.92vh), 100px) vars.$page-inline-padding;

    border-radius: $border-radius;

    &::after {
      @include mixins.dynamic-gradient-bg($gradient, 15s, 200% 100%);

      content: '';

      position: absolute;
      z-index: -1;
      inset: 0;

      opacity: 0;
      border-radius: $border-radius;

      transition: opacity 0.5s linear;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &__title-group {
    text-align: center;
  }

  &__title {
    @include mixins.section-title;

    margin-bottom: clamp(0.625rem, 1.042vw, 1.25rem);
  }

  &__description {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem), Tsvetnoy-Book);
  }

  &__join-link {
  }
}
