@use '@app/styles/colors';
@use '@app/styles/mixins';

.field-label {
  @include mixins.font(clamp(0.75rem, 0.833vw, 1rem), $weight: 500);

  position: relative;
  color: colors.$faint-text-color;
}

.field-edit-icon {
  cursor: pointer;

  position: absolute;
  inset: auto 0 0.4em auto;

  min-width: 16px;
  max-width: 16px;
  height: 16px;
}

.text-input {
  @include mixins.font(clamp(0.75rem, 1.04vw, 1.25rem), $weight: 500);

  padding-block: 0.4em;

  color: colors.$main-text-color;

  background: transparent;
  border: 0;
  border-bottom: solid 1px colors.$faint-text-color;
  border-radius: 0;
  caret-color: colors.$main-text-color;

  &:focus {
    border-color: colors.$main-text-color;
  }
}

.textarea {
  @include mixins.font(clamp(0.75rem, 1.04vw, 1.25rem), $weight: 500);

  resize: none;

  width: 100%;
  padding: 1em;

  color: colors.$main-text-color;

  border: solid 1px colors.$dim-text-color;
  border-radius: 1.2em;
}

.error:is(input, select, textarea) {
  border-color: colors.$error-color;
}

.checkbox-input {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  clip: rect(0 0 0 0);
  border: 0;
}

.checkbox-input-label {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  gap: 0.75rem;

  &::before {
    content: '';

    flex-shrink: 0;

    width: 20px;
    height: 20px;

    background-color: white;
    border: solid 1px #ccc;
    border-radius: 4px;
  }

  &:has(.checkbox-input:checked)::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.242 8a.786.786 0 0 1-.269-.045.624.624 0 0 1-.232-.152L.663 4.725a.663.663 0 0 1-.189-.51.722.722 0 0 1 .206-.51.679.679 0 0 1 .502-.197c.202 0 .37.065.5.197l2.56 2.559L10.309.197a.69.69 0 0 1 .51-.197.69.69 0 0 1 .51.197.69.69 0 0 1 .197.51.69.69 0 0 1-.197.51L4.743 7.803a.624.624 0 0 1-.233.152.787.787 0 0 1-.268.045Z' fill='%23000'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px 8px;
  }

}

.checkbox-input-text {
  user-select: none;
}
