.app {
  min-height: 150vh;

  &__page-fallback {
    height: 150vh;
  }

  /* TODO hardcode */
  &:has(.home-page) > .header:not(.header--filled) {
    background-color: white;
  }
}

.auth + .program-banner,
.account-page + .program-banner,
.about-page + .program-banner {
  display: none;
}
