@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';
@use '@app/styles/mixins.scss';


.footer {
  $border: clamp(1px, 0.104vw, 2px) solid colors.$dim-inverted-border-color;

  content-visibility: auto;
  contain-intrinsic-size: 0 645px;

  padding: clamp(0.5rem, 1.563vw, 1.875rem) vars.$page-inline-padding 0;

  font-size: clamp(1rem, 1.05vw, 1.25rem);
  color: colors.$main-inverted-text-color;

  background-color: colors.$secondary-inverted-bg-color;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 0;

    height: 100%;
    margin-bottom: 2.375rem;
  }

  &__grid-item {
    padding: clamp(1.5rem, 1.563vw, 1.875rem);

    &--1 {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding-left: 0;
    }

    &--2 {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    &--3 {
      grid-column: 3 / 5;
      grid-row: 1 / 2;
      padding-right: 0;
    }

    &--4 {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      padding-left: 0;

      border-top: $border;
      border-right: $border;
    }

    &--5 {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      border-top: $border;
      border-right: $border;
    }

    &--6 {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
      border-top: $border;
      border-right: $border;
    }

    &--7 {
      grid-column: 4 / 5;
      grid-row: 2 / 3;
      padding-right: 0;
      border-top: $border;
    }
  }

  &__social,
  &__contacts,
  &__opening-hours,
  &__parking-prices {
    display: flex;
    flex-direction: column;
    gap: clamp(0.5rem, 0.938vw, 1.125rem);
    line-height: 1.4;
  }

  &__logo {
    width: clamp(175px, 11vw, 240px);
    fill: colors.$main-inverted-text-color;
  }

  &__navbar {
  }

  &__navbar-list {
    display: flex;
    flex-direction: column;
    gap: clamp(1.125rem, 1.05vw, 1.25rem);
  }

  &__navbar-item,
  &__email-input {
    @include mixins.font(clamp(1.125rem, 1.667vw, 2rem), $weight: 500);
  }

  &__navbar-item,
  &__social-link,
  &__info-link,
  &__copyright {
    @include mixins.underline(colors.$main-inverted-underline-color);
  }

  &__email-input {
    width: 100%;
    height: clamp(1.5rem, 1.667vw, 2rem);
    margin-bottom: 0.5rem;
    padding-block: 0.5em 2em;

    color: colors.$main-inverted-text-color;

    background-color: transparent;
    border: 0;
    border-bottom: 1px solid colors.$main-inverted-border-color;
    caret-color: colors.$main-inverted-text-color;
  }

  &__email-label {
    position: relative;
    display: block;
  }

  &__email-label-text {
    font-family: Tsvetnoy-Book;
  }

  &__email-submit-icon {
    rotate: -90deg;
    width: clamp(28px, 2.1vw, 40px);
    height: 100%;
    fill: colors.$main-inverted-text-color;
  }

  &__email-submit {
    position: absolute;
    top: 0;
    right: 0;

    margin-left: auto;

    background: transparent;
  }

  &__subtitle {
    margin-bottom: 0.25rem;
    font-size: clamp(1rem, 1.25vw, 1.5rem);
    font-weight: 500;
  }

  &__text {
    font-family: Tsvetnoy-Book;
    line-height: 140%;

    strong {
      font-family: Tsvetnoy-Medium;
      font-weight: 500;
    }
  }

  &__social-links {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(1.5rem, 2.083vw, 2.5rem);
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    padding: clamp(1.5rem, 1.563vw, 1.875rem) 0;

    border-top: $border;
  }

  &__info-links {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media #{vars.$mobile} {
    $border: clamp(1px, 0.104vw, 2px) solid colors.$secondary-inverted-border-color;

    padding: clamp(0.5rem, 1.563vw, 1.875rem) 0 0;

    &__grid {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    &__grid-item,
    &__info {
      padding: clamp(1.5rem, 1.563vw, 1.875rem) vars.$page-inline-padding;
      border-right: none;
    }

    &__grid-item {
      &--1,
      &--2 {
        padding-bottom: clamp(0.54rem, 2.361vw, 0.68rem);
      }

      &--5,
      &--6,
      &--7 {
        border-top: $border;
      }
    }

    &__social-links {
      justify-content: space-between;
      margin-top: 0.75rem;
    }

    &__email-input {
      margin-bottom: 0.75rem;
      border-bottom-color: #393939;
    }

    &__info {
      flex-direction: column;
      border-top: $border;
    }

    &__info-links {
      flex-direction: column;
      align-items: normal;
    }

    &__copyright {
      text-align: end;
    }

    &__copyright-tooltip {
      display: none;
    }
  }
}

