@use '@app/styles/colors';
@use '@app/styles/vars';

.popover-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{vars.$mobile} {
    align-items: flex-end;
  }
}

.popover {
  $padding: clamp(20px, 2.08vw, 40px);

  position: relative;
  padding: $padding;
  background-color: colors.$main-bg-color;
  border-radius: $padding;

  @media #{vars.$mobile} {
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__close-btn {
    position: absolute;
    inset: $padding $padding auto auto;
  }
}
