@use '@app/styles/colors';
@use '@app/styles/vars';
@use '@app/styles/mixins';

@mixin small-text {
  font-size: clamp(0.875rem, 0.833vw, 1rem);
}

@mixin medium-icon {
  --arrow-icon-size: 40px;
}

@mixin small-icon {
  --arrow-icon-size: 28px;
}

.button {
  display: inline-flex;
  gap: 0.5em;
  align-items: center;

  padding-block: clamp(0.875rem, 1.354vw, 1.625rem);

  font-size: clamp(1rem, 1.25vw, 1.5rem);
  color: colors.$main-text-color;


  transition-duration: 0.5s;

  &--outlined {
    justify-content: center;

    padding-inline: clamp(0.875rem, 1.354vw, 1.625rem);

    background-color: colors.$main-bg-color;
    border: 1px solid colors.$main-border-color;
    border-radius: 6.25rem;
  }

  &__icon {
    rotate: -90deg;

    width: var(--arrow-icon-size);
    min-width: var(--arrow-icon-size);
    height: var(--arrow-icon-size);

    transition-duration: 0.3s;
  }

  &:hover > &__icon {
    transform: translateY(20%) scale(1.2);
  }

  &--outlined:hover > &__icon {
    fill: colors.$main-inverted-text-color;
  }

  &--outlined:not(&--disabled):hover {
    color: colors.$main-inverted-text-color;
    background-color: colors.$main-inverted-bg-color;
  }

  &--invert-colors#{&} {
    color: colors.$main-inverted-text-color;
    background-color: colors.$main-inverted-bg-color;

    .button__icon {
      fill: colors.$main-inverted-text-color;
    }

    &:is(.button--outlined):hover {
      color: colors.$main-text-color;
      background-color: colors.$main-bg-color;

      .button__icon {
        fill: colors.$main-text-color;
      }
    }
  }

  &--arrow-left#{&} {
    flex-direction: row-reverse;

    .button__icon {
      rotate: 90deg;
    }
  }

  &--disabled {
    filter: opacity(0.3);
  }

  &--medium-text {
    font-size: clamp(1rem, 1.25vw, 1.5rem);
  }

  &--small-text {
    @include small-text;
  }

  &--medium-icon {
    @include medium-icon;
  }

  &--small-icon {
    @include small-icon;
  }

  @media #{vars.$mobile} {
    @include small-text;
    @include small-icon;
  }

  &--navbar-link {
    padding: 0;
    font-size: clamp(1.125rem, 1.5vw, 1.5rem);
    @media #{vars.$portable} {
      font-size: clamp(1rem, 2.22vw, 2rem);
      padding-top: clamp(1rem, 2.22vw, 2rem);
    }
  }

  &--account-page-tab {
    @include mixins.font(clamp(1rem, 1.25vw, 1.5rem));
  }
}

.loaderWrap {
  position: relative;
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #0000;
  border-right-color: #9bebe897;
  left: -30px;
  position: absolute;

  animation: l24 1s infinite linear;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.loader:after {
  animation-duration: 4s;
}
@keyframes l24 {
  100% {transform: rotate(1turn)}
}
