@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';
@use '@app/styles/mixins.scss';

.header {
  position: fixed;
  z-index: 10;
  inset: 0 0 auto;

  display: flex;
  gap: clamp(6px, 2.1vw, 40px);
  align-items: center;
  justify-content: space-between;

  min-height: vars.$header-height;
  padding: 0 vars.$page-inline-padding;

  transition-duration: 0.4s;

  &--filled {
    background-color: colors.$main-bg-color;
  }

  &--hidden {
    transform: translateY(calc(-1 * #{vars.$header-height}));
  }

  &__logo {
    cursor: pointer;
    width: clamp(108px, 11vw, 240px);
    min-width: clamp(108px, 11vw, 240px);
  }

  &__links {
    display: flex;
    gap: clamp(20px, 1.6vw, 32px);
    margin: 0;
    font-size: clamp(1.125rem, 1.5vw, 1.5rem);
  }

  &__link {
    @include mixins.underline;

    @media #{vars.$desktop} {
      white-space: nowrap;
    }

    @media #{vars.$portable} {
      white-space: break-spaces;
    }
  }

  &__right {
    display: flex;
    flex: 0 1 auto;
    gap: clamp(16px, 2vw, 20px);
    align-items: center;
  }

  &__controls {
    display: flex;
    gap: 20px;
  }

  &__control {
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 40px;
  }

  &__nav-n-search {
    position: relative;

    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__burger {
    display: none;
    flex-shrink: 0;
  }

  &__burger-header {
    min-height: vars.$header-height;
  }

  &__burger-content {
    display: flex;
    flex-direction: column;
  }

  &__lang.dropdown__inner {
    gap: 0.5em;

    min-width: unset;
    padding: 0;

    font-size: inherit;

    border: unset;

    .dropdown__icon {
      min-width: unset;
      max-width: 10px;
    }
  }
}

@media #{vars.$portable} {
  .header {
    &__nav {
      padding-top: 1rem;
    }

    &__links {
      flex-direction: column;
      gap: clamp(1rem, 2.22vw, 2rem);
      font-size: clamp(1rem, 2.22vw, 2rem);
    }

    &__controls {
      flex-direction: column;
      gap: clamp(1rem, 2.22vw, 2rem);
      align-items: self-start;

      margin-bottom: 0.4rem;

      font-size: clamp(0.875rem, 1.944vw, 1.75rem);
    }

    &__control {
      @include mixins.underline();
    }

    &__burger {
      display: block;
    }

    &__burger-content {
      gap: clamp(30px, 2.778vw, 40px);
      justify-content: space-between;
    }
  }
}
