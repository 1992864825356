@use '@app/styles/vars.scss';
@use '@app/styles/mixins.scss';

.burger-menu {
  &__menu {
  }

  &__btn {
    cursor: pointer;
    background-color: transparent;
    position: relative;
  }

  &__container {
    @include mixins.dynamic-gradient-bg(
      linear-gradient(
        15deg,
        rgb(255 255 0) 15%,
        rgba(66, 238, 158) 35%,
        #f7f7f7 65%
      ),
      20s
    );

    padding: 0 vars.$page-inline-padding clamp(1rem, 3.8vw, 4.625rem) clamp(1rem, 3.8vw, 4.625rem);
    min-width: 15rem;
  }

  &__content {
    overflow-y: auto;
    padding-right: 1rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: vars.$header-height;
  }
}
