/* Media */
$page-min-width: 320px;
$page-max-width: 1920px;
$page-max-height: 1440px;

$desktop-max-width: $page-max-width;
$desktop-min-width: 1440px;

$tablet-max-width: ($desktop-min-width - 1);
$tablet-min-width: 768px;

$mobile-max-width: ($tablet-min-width - 1);
$mobile-min-width: $page-min-width;
$mobile-max-height: 1004px;

$desktop: 'screen and (min-width: #{$desktop-min-width})';

$mobile: 'screen and (max-width: #{$mobile-max-width}), screen and (min-width: #{$tablet-min-width}) and (max-width: #{$mobile-max-height}) and (max-height: #{$mobile-max-width})';

$tablet: 'screen and (min-width: #{$tablet-min-width}) and (max-width: #{$tablet-max-width}) and (min-height: #{$tablet-min-width}), screen and (min-width: #{$mobile-max-height + 1}) and (max-width: #{$tablet-max-width}) and (max-height: #{$tablet-min-width})';

$portable: 'screen and (max-width: #{$tablet-max-width})';

$not-mobile: '#{$tablet}, #{$desktop}';

/* Other vars */

$page-inline-padding: max(env(safe-area-inset-right), env(safe-area-inset-left), clamp(1rem, 3.8vw, 4.625rem));
$header-height: clamp(72px, 5.5vw, 104px);

/* Exports */

:export {
  isDesktop: #{$desktop};
  isTablet: #{$tablet};
  isMobile: #{$mobile};
  isPortable: #{$portable};
  desktopMaxWidth: $desktop-max-width;
  desktopMinWidth: $desktop-min-width;
  mobileMaxWidth: $mobile-max-width;
  mobileMinWidth: $mobile-min-width;
  tabletMaxWidth: $tablet-max-width;
  tabletMinWidth: $tablet-min-width;
}
