@use '@app/styles/vars.scss';
@use '@app/styles/colors.scss';
@use '@app/styles/mixins.scss';

.no-internet {
  background-color: #FFF4F4;
  border-radius: 20px;
  padding: clamp(12px, 5vw, 20px);
  gap: 16px;
  position: fixed;
  top: calc(vars.$header-height + 1rem);

  right: vars.$page-inline-padding;
  margin-left: vars.$page-inline-padding;
  max-width: 490px;
  box-shadow: 0 1px 17px rgb(0 0 0 / 13%);
  z-index: 12;

  display: none;

  transition: display 2s linear;

  &.show {
    display: flex;
  }

  &__info-wrapper {
    display: flex;
    gap: 16px;
  }

  &__title {
    font-size: clamp(16px, 2vw, 20px);
  }

  &__text {
    color: #1f1f1f99;
    font-size: clamp(14px, 2vw, 16px);
    margin-top: 0.5rem;
  }

  &__close-btn {
    height: fit-content;
  }

  & svg {
    max-width: none;
  }
}
