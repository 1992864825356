@use '@app/styles/vars.scss';
@use '@app/styles/mixins.scss';

.badge {
  background: #FF4D4D;
  color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  
  &.desktop {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    line-height: 14px;
  }

  &.burger {
    width: 12px;
    height: 12px;
    position: absolute;
    right: -4px;
    top: -2px;
    font-size: 11px;
    line-height: 11px;
  }

  &.drawer {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 15px;
  }
}
